'use client'

import { motion } from 'framer-motion'
import { ArrowRight } from 'lucide-react'
import { ReactNode, useState } from 'react'

import { Link } from '../Link'
import { Text } from '../Text'

export interface CardLinkProps {
  heading: string
  url: string
  children: ReactNode
}

export const CardLink = ({ heading, url, children }: CardLinkProps) => {
  const [hovering, setHovering] = useState(false)

  return (
    <Link
      href={url}
      onBlur={() => setHovering(false)}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <motion.div
        initial={{
          y: 0,
          boxShadow: '0px 4px 20px 5px rgba(211, 211, 211, 0)',
        }}
        animate={
          hovering
            ? {
                y: -3,
                boxShadow: '0px 4px 20px 5px rgba(211, 211, 211, 0.25)',
              }
            : {}
        }
        transition={{
          duration: 0.4,
        }}
        className="bg-white border border-baby-blue rounded-16px p-8 inline-flex flex-col gap-4 w-full"
      >
        <Text variant="cardLinkTitle" as="h3">
          {heading}
        </Text>
        <div className="flex items-center w-full gap-4">
          <Text
            as="div"
            variant="cardLinkText"
            className="text-gray-3 line-clamp-2"
          >
            {children}
          </Text>
          <div className="shrink-0">
            <motion.div
              initial={{
                backgroundColor: 'var(--color-gray-1)',
                color: 'var(--color-dark)',
              }}
              animate={
                hovering
                  ? {
                      backgroundColor: 'var(--color-blue)',
                      color: 'var(--color-white)',
                    }
                  : {}
              }
              transition={{
                duration: 0.4,
              }}
              className="rounded-full w-[25px] h-[25px] inline-flex items-center justify-center"
            >
              <ArrowRight width={14} />
            </motion.div>
          </div>
        </div>
      </motion.div>
    </Link>
  )
}

export default CardLink
