'use client'

import * as Accordion from '@radix-ui/react-accordion'
import { AnimatePresence, motion } from 'framer-motion'
import { ArrowRight, X } from 'lucide-react'
import React, { useCallback, useRef, useState } from 'react'

import useAnswerScroll from './shared'
import { FaqAccordionProps } from './types'
import { Button } from '../Button'
import { Link } from '../Link'
import { RichText } from '../RichText'
import { Text } from '../Text'

import { cn } from '@/lib/utils'

export const FaqAccordion = ({
  id,
  noScroll,
  heading,
  items = [],
  labelSeeMore = 'Sjá fleiri spurningar',
  labelClickMore = 'Sjá allt',
  cutOff = 5,
  href,
}: FaqAccordionProps) => {
  const container = useRef<HTMLDivElement>(null)

  const [visibleCount, setVisibleCount] = useState<number>(
    Math.min(items.length, cutOff),
  )
  const [hoveringIndex, setHoveringIndex] = useState<number | undefined>(
    undefined,
  )
  const [openValues, setOpenValues] = useState<string[]>([])

  const { onClick } = useAnswerScroll(id, container, setOpenValues)

  const onShowAll = useCallback(() => {
    setVisibleCount(items.length)
  }, [items.length])

  return (
    <div className="gutter" ref={container}>
      {heading && (
        <div>
          <div className="mb-12 md:mb-24 w-full text-center md:text-left">
            <Text variant="h3" as="h3">
              {heading}
            </Text>
          </div>
        </div>
      )}
      <Accordion.Root
        id={id}
        type="multiple"
        value={openValues}
        onValueChange={(value) => {
          setOpenValues(value)
        }}
      >
        {items
          .slice(0, visibleCount)
          .map(({ heading, questionSlugString, text }, index) => {
            const formattedNumber = (index + 1).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })

            const idx = `${id}-${index}`
            const isOpen = openValues.includes(idx)
            const isHovering = hoveringIndex === index
            const slug = `${questionSlugString}-${index}`

            return (
              <Accordion.Item
                key={index}
                value={idx}
                className="border-b-[0.5px] border-gray-4"
              >
                <Accordion.Header>
                  <Accordion.Trigger asChild>
                    <button
                      id={`#${slug}`}
                      onClick={() => {
                        !noScroll && onClick(slug, isOpen)
                      }}
                      className="flex w-full gutter py-14 items-center outline-none focusable"
                      onFocusCapture={() => setHoveringIndex(index)}
                      onBlur={() => setHoveringIndex(index)}
                      onMouseEnter={() => setHoveringIndex(index)}
                      onMouseLeave={() => setHoveringIndex(undefined)}
                    >
                      <div className="text-gray-3 min-w-[50px] md:min-w-[150px] text-left">
                        <Text variant="button" as="span">
                          {formattedNumber}
                        </Text>
                      </div>
                      <div className="w-full">
                        <div className="max-w-[564px] text-left">
                          <Text variant="label-medium">{heading}</Text>
                        </div>
                      </div>
                      <Chevron
                        active={isOpen}
                        hovering={isHovering}
                        className="self-center"
                      />
                    </button>
                  </Accordion.Trigger>
                  <AnimatePresence>
                    {openValues.includes(idx) && (
                      <Accordion.Content forceMount asChild>
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{
                            height: 'auto',
                            transition: {
                              duration: 0.3,
                              delay: 0,
                            },
                          }}
                          exit={{
                            height: 0,
                            transition: {
                              duration: 0.3,
                              delay: 0.3,
                            },
                          }}
                          className="text-gray-5"
                        >
                          <motion.div
                            initial={{
                              opacity: 0,
                              y: -15,
                            }}
                            animate={{
                              opacity: 1,
                              y: 0,
                              transition: {
                                duration: 0.3,
                                delay: 0.3,
                              },
                            }}
                            className="text-gray-5 flex flex-row gutter"
                          >
                            <motion.div
                              initial={{
                                opacity: 0,
                                y: -15,
                              }}
                              animate={{
                                opacity: 1,
                                y: 0,
                                transition: {
                                  duration: 0.3,
                                  delay: 0.3,
                                },
                              }}
                              exit={{
                                opacity: 0,
                                y: -15,
                                transition: {
                                  duration: 0.3,
                                  delay: 0,
                                },
                              }}
                              className="w-full flex flex-row"
                            >
                              <div className="min-w-[50px] md:min-w-[150px]" />
                              <div className="w-full max-w-[564px] flex-grow text-gray-4">
                                {typeof text === 'string' ? (
                                  <div className="mb-10">{text}</div>
                                ) : (
                                  <RichText
                                    xSpacing={false}
                                    richText={text}
                                    size="small"
                                  />
                                )}
                              </div>
                            </motion.div>
                          </motion.div>
                        </motion.div>
                      </Accordion.Content>
                    )}
                  </AnimatePresence>
                </Accordion.Header>
              </Accordion.Item>
            )
          })}
      </Accordion.Root>
      <div className="flex gap-4 px-4 md:px-0">
        {items.length !== visibleCount && (
          <div className="flex mt-12">
            <Button onClick={onShowAll} variant="ghost">
              {labelSeeMore}
            </Button>
          </div>
        )}
        {href && href.length > 1 && (
          <div className="flex mt-12">
            <Button as={Link} href={href} variant="ghost" icon="arrow-right">
              {labelClickMore}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default FaqAccordion

export const Chevron = ({
  active,
  hovering,
  className = '',
}: {
  active?: boolean
  hovering?: boolean
  className?: string
}) => {
  return (
    <motion.div
      initial={{
        rotate: 90,
        backgroundColor: 'var(--color-white)',
        color: 'var(--color-dark)',
      }}
      animate={{
        ...(!active &&
          hovering && {
            rotate: 0,
            backgroundColor: 'var(--color-blue)',
            color: 'var(--color-white)',
          }),
        ...(active &&
          hovering && {
            rotate: 0,
            backgroundColor: 'var(--color-blue)',
            color: 'var(--color-white)',
          }),
      }}
      transition={{
        duration: 0.4,
        delay: 0,
      }}
      className={cn(
        'rounded-full min-w-[25px] w-[25px] h-[25px] inline-flex items-center justify-center',
        className,
      )}
    >
      <motion.div
        className="absolute"
        initial={{
          opacity: 1,
        }}
        animate={{
          opacity: active ? 0 : 1,
        }}
        transition={{
          duration: 0.4,
          delay: active ? 0 : 0.4,
        }}
      >
        <ArrowRight width={14} />
      </motion.div>
      <motion.div
        className="absolute"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: active ? 1 : 0,
        }}
        transition={{
          duration: 0.4,
          delay: active ? 0.4 : 0,
        }}
      >
        <X width={16} />
      </motion.div>
    </motion.div>
  )
}
