import clsx from 'clsx'
import { PortableTextReactComponents } from 'next-sanity'

import { CustomImage } from '@/components/CustomImage'
import { Link } from '@/components/Link'
import { Text } from '@/components/Text'
import { ITextProps } from '@/components/Text/Text'
import { cn, getEmbedUrl } from '@/lib/utils'
import { linkResolver } from '@/sanity/lib/linkResolver'
import { PageContentType } from '@/types/general'

export type Props = {
  xSpacing?: boolean
  rSpacing?: boolean
  size?: 'normal' | 'small'
  contentType?: PageContentType
  locale?: string
}

type CmpType = Partial<PortableTextReactComponents>

export const defaultComponents = ({
  xSpacing,
  size,
  rSpacing,
  locale,
}: Props): CmpType => {
  const textSizeClassName = {
    /** @tw */ 'text-body-m md:text-body': size === 'normal',
    /** @tw */ 'text-body-small-m md:text-body-small': size === 'small',
  }

  return {
    block: {
      normal: (node) => (
        <Text
          className={cn(
            'mb-5 md:mb-10 antialiased',
            {
              'md:px-36': xSpacing,
              'lg:pr-36': rSpacing,
            },
            textSizeClassName,
          )}
        >
          {node.children}
        </Text>
      ),
      p: (node) => (
        <Text
          className={cn(
            'mb-5 md:mb-10 antialiased',
            {
              'md:px-36': xSpacing,
            },
            textSizeClassName,
          )}
        >
          {node.children}
        </Text>
      ),
      'medium-feature': (node) => (
        <Text
          variant="tiny-feature"
          className={clsx(
            'mt-10 md:mt-20 mb-5 md:mb-10 text-trueBlue antialiased',
            {
              'lg:max-w-[700px]': rSpacing,
            },
          )}
        >
          {node.children}
        </Text>
      ),
      'heading-2-m': (node) => (
        <Text className="my-10 md:my-20 antialiased" variant="h4">
          {node.children}
        </Text>
      ),
    },
    list: {
      bullet: (node) => (
        <ul
          className={cn(
            'list-disc grid gap-3 pl-4 mb-5 md:mb-10',
            {
              'md:px-40': xSpacing,
            },
            textSizeClassName,
          )}
        >
          {node.children}
        </ul>
      ),
      number: (node) => (
        <ol
          className={cn(
            'list-decimal grid gap-3 pl-9 mb-5 md:mb-10',
            {
              'md:px-44': xSpacing,
            },
            textSizeClassName,
          )}
        >
          {node.children}
        </ol>
      ),
    },
    listItem: (node) => {
      let variant: ITextProps['variant']

      switch (size) {
        case 'normal':
          variant = 'p'
          break
        case 'small':
          variant = 'pSmall'
          break
      }

      return (
        <li>
          <Text className="antialiased" variant={variant}>
            {node.children}
          </Text>
        </li>
      )
    },
    types: {
      imageWithProperties: (node) => {
        return (
          <div className="relative aspect-video mb-5 md:mb-10 rounded-16px overflow-hidden">
            <CustomImage sanityImageData={node.value} />
          </div>
        )
      },
      videoItem: (node) => {
        const embedSrc =
          node?.value?.embed && node?.value?.embedUrl
            ? getEmbedUrl(node.value.embedUrl)
            : null

        const video = node
          ? [
              {
                contentType: node?.value?.asset?.asset?.mimeType ?? '',
                url: node?.value?.asset?.asset?.url ?? '',
              },
            ]
          : null

        return (
          <div className="mb-5 md:mb-10 rounded-16px overflow-hidden">
            <div className="aspect-video w-full outline">
              {!embedSrc && video && (
                <video
                  height="100%"
                  width="100%"
                  playsInline
                  controls={!!node?.value?.controls}
                  autoPlay={!!node?.value?.autoplay}
                  muted={!!node?.value?.muted || !!node?.value?.autoplay}
                  loop={!!node?.value?.loop}
                >
                  {video.map(({ contentType, url }, index) => {
                    return <source key={index} src={url} type={contentType} />
                  })}
                </video>
              )}
              {embedSrc && (
                <iframe
                  src={embedSrc}
                  height="100%"
                  width="100%"
                  allow="autoplay; fullscreen; picture-in-picture"
                  className="relative h-full"
                  title={'title' ?? ''}
                  allowFullScreen={true}
                />
              )}
            </div>
          </div>
        )
      },
    },
    marks: {
      innerPathLink: (node) => {
        const { href } = node.value

        return (
          <Link
            className={cn('inline-block font-bold', textSizeClassName)}
            href={href}
          >
            <span className="text-blue underline">{node.children}</span>
          </Link>
        )
      },
      internalLink: (node) => {
        const slug = node.value?.slug
        let href = slug ? `/${slug.current}` : '/'

        const type = node?.value?.reference?._type

        if (type && type === 'iframeForm') {
          href = linkResolver(slug, type, locale)
        }

        return (
          <Link
            className={cn('inline-block font-bold', textSizeClassName)}
            href={href}
          >
            <span className="text-blue underline">{node.children}</span>
          </Link>
        )
      },
      externalLink: (node) => {
        const { href } = node.value

        return (
          <Link
            newTab
            className={cn('inline-block font-bold', textSizeClassName)}
            href={href}
          >
            <span className="text-blue underline">{node.children}</span>
          </Link>
        )
      },
    },
  }
}

export default defaultComponents
