import { PortableText, PortableTextReactComponents } from '@portabletext/react'

import defaultComponents, { Props } from './components/default'

import { PageContentType } from '@/types/general'
import { RichText as RichTextType } from '@/types/sanity.types'

export interface RichTextProps extends Props {
  richText?: RichTextType
  components?: Partial<PortableTextReactComponents>
  contentType?: PageContentType
  locale?: string
}

export const RichText = ({
  richText,
  components,
  xSpacing = true,
  rSpacing = false,
  size = 'normal',
  contentType,
  locale,
}: RichTextProps) => {
  if (!richText) return null

  const defCmps = defaultComponents({
    xSpacing,
    rSpacing,
    size,
    ...(contentType && { contentType }),
    locale: locale ?? 'is',
  })

  return (
    <PortableText
      value={richText}
      components={{
        ...defCmps,
        ...components,
      }}
    />
  )
}

export default RichText
