import { useRouter } from 'next/navigation'
import { Dispatch, RefObject, useEffect } from 'react'
import { scroller } from 'react-scroll'

export const useAnswerScroll = (
  id: string,
  container: RefObject<HTMLDivElement>,
  change: Dispatch<React.SetStateAction<string[]>>,
) => {
  const router = useRouter()
  const isBrowser = typeof window === 'object'

  useEffect(() => {
    if (container.current && isBrowser) {
      const hash = window.location.hash
      const index = hash.slice(-1)

      const elementId = hash.slice(1)

      if (container.current.querySelector(`button[id="#${elementId}"]`)) {
        scroller.scrollTo(hash, {
          duration: 800,
          delay: 0,
          offset: -100,
          smooth: 'easeInOutQuart',
        })

        const idx = `${id}-${index}`

        change((state) => [...new Set([...state, idx])])
      }
    }
  }, [change, container, id, isBrowser])

  const onClick = (slug: string, isOpen?: boolean) => {
    if (isBrowser) {
      const url = new URL(window.location.href)
      url.hash = !isOpen ? slug : ''
      router.replace(url.href, { scroll: false })
    }
  }

  return {
    onClick,
  }
}

export default useAnswerScroll
